import { useRouter } from 'next/router';
import React from 'react';
import { useEffect } from 'react';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';

export default function Index() {
	const { push } = useRouter();
	useEffect(() => {
		push('/dashboard');
	}, [push]);
	return <LoadingScreen />;
}
