import React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export default function LoadingScreen() {
	return (
		<div className='w-screen h-screen flex-col bg-blueGrey-700 flex items-center justify-center'>
			<img
				src='/images/logo-black.svg'
				alt='stryve logo'
				className='my-8 w-64'
			/>
			<LoadingSpinner />
		</div>
	);
}
